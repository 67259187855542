import React, { useEffect, useState } from 'react'
import {logoBlue,baseURL} from '../../../Config.js';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import './DeleteAccount.css';

import HashLoader from "react-spinners/HashLoader";
function DeleteAccount({showOtp,status}) {

const loginURL = 'api/user/delete/'

const {
  register,
  handleSubmit,
  formState: { errors },
} = useForm({
  mode: "all",
});



//mobile number
const [mobile, setMobile] = useState("")


//make buton login or send api reque
const [makeBtnLogin, setMakeBtnLogin] = useState(false)
const [isLoading, setisLoading] = useState(false)

//show error message
const [disableError, setDisableError] = useState(true)

//error message display 
const [message, setMessage] = useState("")


//navigate to
const navigate = useNavigate();

//SIGNUP
const onSubmit = (data) => {

  //data getting from form
  setMobile(data.mobile)
  setisLoading(true);
  if(makeBtnLogin)
  {
    axios
    .delete(baseURL+loginURL, {
      data: {
        mobile: data.mobile,
        otp:data.otp,
      }
    })
    .then((response) => {
      if(response.data.status)
      {
        setisLoading(false);
        alert("User has been successfully Deleted !");
        navigate('/')

      }

      else
      {
        setisLoading(false);
        setDisableError(false)
        setMessage(response.data.message);
      }
     
    }).catch(error => {
      setisLoading(false);
      setDisableError(false)
      setMessage(error.response.data.message);
      console.log(error.response)
   })  ;

  }
  else
  {
    //initial validaition here
    setisLoading(true);
    axios
    .delete(baseURL+loginURL, {
      data: {
        mobile: data.mobile,
      }
    }
      )
    .then((response) => {
      
      console.log(response, "response-------")
      setisLoading(false);
      setMakeBtnLogin(response.data.status);
      status.showTheOtp(response.data.status);
      
    }).catch(error => {
      setisLoading(false);
      setDisableError(false)
      setMessage(error.response.data.message);
      console.log(error.response)
   })  
  }
}









useEffect(() => {

}, [status])

  return (
   <>

    {isLoading?<div className={isLoading ? 'parentDisableLight' : ''} width="100%">
      <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
    </div>:null}

   <div className="col-xl-7 col-md-6 col-lg-6  col-12">
   <div className="d-flex flex-wrap bd-highlight align-items-center">

      <div > <h2  className='mb-0' style={{fontWeight:"600", color:"red",fontSize:"1.9rem"}}  >Delete Your Account</h2> </div>

    </div>



        <div className='mt-3'>
  
        <form onSubmit={handleSubmit(onSubmit)}>




       {showOtp?

          //otp
          <>
          <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Mobile Number</label>
              <div className="form-group has-icon mb-2">
              <span className="form-control-feedback">+91</span>
              <div className="form-control rounded-0 paddingonLoginandSignUp">
              {mobile} <span onClick={()=>{status.showTheOtp(false);setMakeBtnLogin(false);}} style={{color:'red',fontSize:'0.7rem'}}>Change</span> </div>
          </div>


         <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Enter OTP</label>
         <div className="form-group has-icon mb-2">
         <span className="form-control-feedback">OTP</span>
         <input
           maxLength={6}
           onKeyPress={ (e)=>setDisableError(true)}
           className="form-control rounded-0 paddingonLoginandSignUp" 
         {...register("otp", {
           required: "Please Enter OTP",
           minLength: {
             value: 6,
             message: "Enter 6 Digit OTP",
           },
          
         })}
         placeholder="_ _ _ _ _ _"
       />
     <p className='m-0' style={{color:"red"}}>{errors.otp?.message}</p>
     
     </div>
     </>
     :      <>
     <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Mobile Number</label>
         <div className="form-group has-icon mb-2">
         <span className="form-control-feedback">+91</span>
         <input
           onInput={(e) => {
            setDisableError(true);
            const newValue = e.target.value.replace(/[^\d]/g, "");
            e.target.value = newValue;
          }}
           maxLength={10}
           onChange={ (e)=>{ status.showTheOtp(false); setMakeBtnLogin(false);  }}
           className="form-control rounded-0 paddingonLoginandSignUp" 
         {...register("mobile", {
           required: "mobile is Required...",
           minLength: {
             value: 10,
             message: "Please Enter 10 Digit Mobile Number",
           },
          
         })}
         placeholder="xxx - xx - xxx"
       />
     <p className='m-0' style={{color:"red"}}>{errors.mobile?.message}</p>
     
     </div> </> } 

       {!disableError?<span style={{color:"red"}}> {message}  </span>:null     }  



        <input type='submit' className='btn btn-danger btn-lg w-100 rounded-0 p-2 shadow ' value="Delete" />
        </form> 


        </div>
        <p className='text-secondary text-center mt-3 mb-0 text-break ' style={{fontSize:'0.7rem'}}>This will delete all your details from iwillfly & cannot be recovered , You can also change decision and <Link to="/login" className='text-decoration-none'>Login Here </Link>
          </p>

        </div>
       
   
   </>
  )
}

export default DeleteAccount