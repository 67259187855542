import React, { useState }from 'react'
import NavBar from '../NavBar/NavBar'
import {noDataFoundimg} from '../../Config.js'
import DeleteAccount from './DeleteAccount/DeleteAccount.js'

function DeleteMyAccountContainer() {
    
  const [showLogin, setShowLogin] = useState(true)
  const [showOtp, setShowOtp] = useState(false)

  //mobile
  const [mobile, setMobile] = useState("")


  const showTheLogin=(pass)=>{
    setShowLogin(pass)
  }  

  const showTheOtp=(pass)=>{

    setShowOtp(pass)
  }  


  const passMobile=(pass)=>{
    setMobile(pass)
  }

  return (
    <>
    <NavBar/>
                    
    
    <div  className="d-flex flex-column min-vh-100 justify-content-center align-items-center" 
          style={{backgroundColor: "#eeeeee"}  }>

      <div className="container col-xl-10 col-lg-10 col-12 bg-light shadow p-3 mb-5 bg-body rounded">

      <div className="row p-5 align-items-center  ">
                <div className="col-xl-5 col-md-6 col-lg-6  col-12"><img src={noDataFoundimg} style={{maxWidth: "100%",maxHeight: "100%"}} alt="logo" /></div>




    <DeleteAccount showOtp={showOtp} status={{showTheLogin,showTheOtp,passMobile}}   />
          
      </div>


      </div>
  </div>


</>
  )
}

export default DeleteMyAccountContainer